
// .page > .page__container {
//   &:not(.page__heading) {
//     margin-top: $grid-gutter-width;
//   }
// }

// .layout-app .page {
//   margin-top: 0;
//   padding-bottom: 0;
// }

// .py-grid {
//   padding-top: $grid-gutter-width;
//   padding-bottom: $grid-gutter-width;
// }

// .page__container,
// .page__heading-container {
//   @include media-breakpoint-up(xl) {
//     padding-left: $grid-gutter-width;
//     padding-right: $grid-gutter-width;
//   }
  

//   + .page__container {
//     margin-top: 0 !important;
//   }
// }
// .layout-fixed,.layout-fluid, .layout-mini {
//   .page__container,
//   .page__heading-container {
//     max-width: 1140px;
//   }
// }

// .page__heading {
//   h1 {
//     line-height: 1;
//   }
//   .lead {
//     font-weight: 400;
//   }
//   padding-top: $grid-gutter-width /2;
//   padding-bottom: $grid-gutter-width/2;
//   // background:#ffffff;

//   @include media-breakpoint-up(xl) {
//     &:not(.page__heading--xl_small) {
//       padding-top: $grid-gutter-width + 10px;
//       padding-bottom: $grid-gutter-width+10px;
//     }
//   }
// }

// .page__header {
//   // background: white;
//   box-shadow: 0 5px 15px 0 rgba(227,227,227,0.50);
//   margin-bottom: $grid-gutter-width/2;
//   @include media-breakpoint-up(xl) {
//     margin-bottom: $grid-gutter-width;
//   }
//   z-index: 3;
//   & + .page__header {
//     z-index: 2;
//   }
//   position: relative;
// }

// // sticky subnav

// .layout-sticky-subnav {
//   .mdk-header-layout {
//     overflow: initial;
//   }
//   .page__header {
//     &-nav {
//       position: sticky;
//       // top: $navbar-height;
//       z-index: 1;
//     }
//     &:first-child {
//       margin-bottom: 0;
//       box-shadow: none;
//     }
//   }
//   .page__container {
//     z-index: 0;
//     position: relative;
//   }
// }


// .mdk-header--shadow::after {
//   box-shadow: 0 5px 15px 0 rgba(227,227,227,0.50);
//   bottom: 0;
//   // height: $navbar-height;
// }

// .projects-item {
//   margin-bottom: 0 !important;
//   padding-bottom: .4375rem;
//   border-left: 1px dotted rgba(55, 77, 103, 0.2);
//   margin-left: 10px;
// }

// .bottom-spacing {
//   margin-bottom: $grid-gutter-width / 2;
  
//   @include media-breakpoint-up(lg) {
//     margin-bottom: $grid-gutter-width;
//   }
// }


// @include media-breakpoint-up(md) {
//   .price-range-width { width: 150px; }
// }
// .min-w-150 {
//   min-width: 300px;
// }
// .min-w-300 {
//   min-width: 300px;
// }
// .js-dragula-handle {
//   cursor: move;
// }

// .h-150 { height:150px !important; }
// .h-250 { height:250px; }
// .h-calc-250 {
//   height: calc(250px - 1.25rem * 2);
// }

// .z-0 {
//   z-index: 0;
// }

// .layout-fixed .page__heading {
//   background: none;
// }


html,
body {
  height: 100%;
  position: relative;
}
.mdk-header-layout,
.mdk-drawer-layout,
.mdk-drawer-layout__content {
  height: initial;
  min-height: 100%;
}

.layout-default .page {
  padding-bottom: $grid-gutter-width;
}

.page__container,
.page__heading-container {
  .layout-mini &,
  .layout-default &,
  .layout-fluid & {
    @include media-breakpoint-up(xl) {
      padding-left: $grid-gutter-width;
      padding-right: $grid-gutter-width;
    }
  }
  .layout-default & {
    max-width: 1300px;
  }
}


.layout-fluid .page {
  .page__container,
  .page__heading-container {
    max-width: 1300px;
  }
}

.page__heading {
  h1 {line-height: 1;}
  padding-top: $grid-gutter-width /2;
  padding-bottom: $grid-gutter-width/2;
  @include media-breakpoint-up(xl) {
    &:not(.page__heading--xl_small) {
      padding-top: $grid-gutter-width;
      padding-bottom: $grid-gutter-width;
      
    }
  }
}
.layout-default, .layout-fixed, .layout-mini
  {
    .page__heading {
      border-bottom:1px solid $border-color;
      margin-bottom: $grid-gutter-width/2;
      @include media-breakpoint-up(xl) {
        &:not(.page__heading--xl_small) {
          margin-bottom: $grid-gutter-width;
        }
      }
    }
}

.page__header {
  background: white;
  box-shadow: 0 5px 15px 0 rgba(0,0,0,0.12) !important;;
  
  margin-bottom: $grid-gutter-width/2;
  @include media-breakpoint-up(xl) {
    margin-bottom: $grid-gutter-width;
  }

  z-index: 3;
  & + .page__header {
    z-index: 2;
  }
  position: relative;
}

// sticky subnav

.layout-sticky-subnav {
  .mdk-header-layout {
    overflow: initial;
  }
  .page__header {
    &-nav {
      position: sticky;
      top: $navbar-height;
      z-index: 1;
    }
    &.flush-shadow {
      margin-bottom: 0;
      box-shadow: none !important;
    }
  }
  &.layout-fixed .page__header:first-child {
    box-shadow: inherit;
  }
  .page__container {
    z-index: 0;
    position: relative;
  }
}

.mdk-header--shadow::after {
  box-shadow: 0 5px 15px 0 rgba(227,227,227,0.50);
  bottom: 0;
  height: $navbar-height;
}

.projects-item {
  margin-bottom: 0 !important;
  padding-bottom: .4375rem;
  border-left: 1px dotted rgba(55, 77, 103, 0.2);
  margin-left: 10px;
}

.bottom-spacing {
  margin-bottom: $grid-gutter-width / 2;
  
  @include media-breakpoint-up(lg) {
    margin-bottom: $grid-gutter-width;
  }
}

.home-banner {
  width:100%;
  height: 200px;
  display: block;
  position: relative;
}

.home-banner {
  background: 
    linear-gradient(
      rgba(0, 0, 0, 0.5),
      rgba(0, 0, 0, 0.5)
    ),
    url('../images/home_banner.jpg');
  background-size: cover;
  min-height:250px;
  height: 50vh;

  .page__container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    text-align: center;
  }
}


.home-banner2 {
  position: relative;
  background: 
    linear-gradient(
      rgba(0, 0, 0, 60),
      rgba(0, 0, 0, .6)
    ),
    url('../images/home_banner2.jpeg');
  background-size: cover;
  min-height:250px;
  height: 80vh;

  .page__container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    text-align: center;
    .lead {
      color: rgba(255,255,255,.54);
    }
  }
}


#footer {
  margin-top: $grid-gutter-width/2 0;
  padding: $grid-gutter-width;
  .brand {
    font-weight:bold;
    font-size: 1.25rem;
    color: rgba(255,255,255, .54);
    &:hover {
      color:#ffffff;
      text-decoration: none;
    }
  }
  h5 { 
    margin-bottom: $grid-gutter-width/2;
    font-weight: bold;
  }
  ul.list-group-flush li {
    list-style: none;
    padding: 8px 0;
    a { color: rgba(255,255,255, .54); }
    a:hover { color: #ffffff; }
  }
}